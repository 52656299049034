import '@babel/polyfill';
import 'intersection-observer';
import 'eligrey-classlist-js-polyfill';
import 'nodelist-foreach-polyfill';
import 'ie-array-find-polyfill';
import 'custom-event-polyfill';
import 'element-remove';
import objectFitImages from 'object-fit-images';

objectFitImages();
